
export default {
    name: 'TransactionStatus',
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        isMobileScreen: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async clickTrick(evt) {
            if (this.item.status === 1) {
                const confirm = window.confirm(this.tl('uSureToCancel?'))
                if (!confirm) return
                this.loading = true
                const result = await this.$store.dispatch(
                    'themes/withdraw/cancelWithdraw',
                    this.item.id
                )
                if (result) {
                    this.$emit('api')
                }
                this.loading = false
            }
        },
    },
}
